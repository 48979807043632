import React, { useEffect, useState } from 'react';
import axios from 'axios';

const ConfirmationReserveTBK = () => {
  const [reservationData, setReservationData] = useState<any>();
  const [hasError, setHasError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const storedReservationData = localStorage.getItem('reservationData');
  if (storedReservationData) {
    console.log('Data recuperada')
    const reservationData = JSON.parse(storedReservationData);
    console.log(reservationData);
  }

  useEffect(() => {
    const fetchReservation = async () => {
      setHasError(false);
      setIsLoading(true);
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token_ws');
      if (token) {
        try {
          const response = await axios.post('https://apimail.maiposalud.com/apiver/private/patient/recibotbk',
            {
              token: token,
            },
            {
              headers: {
                Authorization: '2374dfa16c7475154d0e3539a7bcf785',
                'Content-Type': 'multipart/form-data',
              }
            }
          );
          setReservationData(response.data);
          setIsLoading(false);
        } catch (error) {
          setHasError(true);
          setIsLoading(false);
          console.error('Error al procesar la reserva:', error);
        }
      } else {
        setHasError(true);
        setIsLoading(false);
      }
    };

    fetchReservation();
  }, []);

  return (
    <div className="container" style={{ textAlign: 'center' }}>

      {isLoading ? (
        <p>Cargando datos de la reserva...</p>
      ) : (
        (hasError || !reservationData) ? (
          <></>
        ) :
          (
            <>
              <h1>Reserva confirmada</h1>
              <div>
                <p>Estado: {reservationData.estado}</p>
                <p>Fecha contable: {reservationData.fechaContable}</p>
                <p>Fecha de transacción: {new Date(reservationData.fechaDeTransaccion).toLocaleString()}</p>
                <p>ID de sesión: {reservationData.idDeSesion}</p>
                <p>Monto: {reservationData.monto}</p>
                <p>Número de cuotas: {reservationData.numeroDeCuotas}</p>
                <p>Orden de compra: {reservationData.ordenDeCompra}</p>
              </div>
              <div>
                <h3>Detalles</h3>
                <p>Fecha de reserva: {reservationData.fecha}</p>
                <p>Hora de reserva: {reservationData.hora}</p>
                <p>RUT del paciente: {reservationData.rut}</p>
                <p>Id profesional: {reservationData.id}</p>
                <p>Facility: {reservationData.facility}</p>
                <p>Categoría: {reservationData.categorie}</p>
              </div>
            </>
          )
      )}
    </div>
  );
}

export default ConfirmationReserveTBK;