import { useLocation } from 'react-router-dom';
import ClientDataContainer from '../components/ClientDataContainer';
import '../styles/HourReservation.css';
import { Client } from '../components/Interfaces';

const HourReservation = () => {
  const location = useLocation();
  const { dataLabels, clientData, dataHour } = location.state as any

  const clientExists = !!clientData.nombre;

  return (
    <div className="appBody">
      <div className="hourReservationMainContainer">
        <section>
          <ClientDataContainer
            clientData={clientData}
            clientExists={clientExists}
            dataHour={dataHour}
            dataLabels={dataLabels}
          />
        </section>
      </div>
    </div>
  );
};

export default HourReservation;
