import axios from 'axios';
import { id } from 'date-fns/locale';
import React from 'react';
import { IoIosAlert } from 'react-icons/io';
import Popup from 'reactjs-popup';

const Terms = ({ handleConfirmHour, client, isLoading, hourData, dataLabels }: any) => {
  const [isChecked, setIsChecked] = React.useState(false);
  console.log('client', client);
  console.log('hourData', hourData);
  console.log('dataLabels', dataLabels);
  const [fecha, hora] = hourData.reservationHour.split(' ');
  const reservationData = {
    rut: client.rut,
    id: hourData.idProfesional,
    fecha: fecha,
    hora: hora,
    facility: 9,
    categorie: hourData.categorie
  }
  localStorage.setItem('reservationData', JSON.stringify(reservationData));
  const handleCheckboxChange = () => setIsChecked(!isChecked);
  const [voucherModal, setVoucherModal] = React.useState(false);

  const handlePayment = async () => {
    const formData = new FormData();
    const buyOrder = `order-${Math.floor(Math.random() * 1000000)}`;
    const sessionId = `session-${Math.floor(Math.random() * 1000000)}`;
    formData.append('servicios[0][nombre]', dataLabels.category);
    formData.append('servicios[0][monto]', '4000');
    formData.append('sessionId', sessionId);
    formData.append('buyOrder', buyOrder);

    try {
      // Enviar solicitud a tu API
      const response = await axios.post('https://apimail.maiposalud.com/apiver/private/patient/procesar-orden',
        formData,
        {
          headers: {
            Authorization: '2374dfa16c7475154d0e3539a7bcf785',
            'Content-Type': 'multipart/form-data',
          }
        }
      );

      console.log('Respuesta del servidor:', response.data);
      const webpayForm = document.createElement('form');
      webpayForm.method = 'POST';
      webpayForm.action = response.data.url;

      const tokenInput = document.createElement('input');
      tokenInput.type = 'hidden';
      tokenInput.name = 'token_ws';
      tokenInput.value = response.data.token;
      webpayForm.appendChild(tokenInput);

      document.body.appendChild(webpayForm);

      webpayForm.submit();

    } catch (error) {
      console.error('Error al procesar el pago:', error);
    }
  };

  const VoucherModal = () => {
    return (
      <Popup open={voucherModal} modal closeOnDocumentClick={false}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '5%', backgroundColor: '#51b543' }}>
          <div>
            <IoIosAlert size={40} color='white' />
          </div>
          <p className='subTitleText'>Resumen</p>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%', backgroundColor: '#51b543', padding: '5%', borderRadius: '10px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5%', color: 'white', fontWeight: 'bold' }}>
              <p>Prestación: {dataLabels.category}</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5%', color: 'white', fontWeight: 'bold' }}>
              <p>Doctor: {hourData.profesionalName}</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5%', color: 'white', fontWeight: 'bold' }}>
              <p>Fecha y hora: {hourData.reservationHour}</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5%', color: 'white', fontWeight: 'bold' }}>
              <p>Valor: $30000</p>
            </div>
          </div>
          <div className="buttons">
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '2%' }}>
              <button className='telemedicina-popup-confirmation-button-left' onClick={() => setVoucherModal(false)}>
                Volver
              </button>
              <button className='telemedicina-popup-confirmation-button'
                onClick={() => {
                  handlePayment();
                }}
              >
                Pagar
              </button>
            </div>
          </div>
        </div>
      </Popup>
    )
  }

  return (
    <div className='appBody'>
      {voucherModal && <VoucherModal />}
      <div className='box-terms'>
        <div className='containerTitle'>Información importante</div>
        <p>
          Estimado paciente,
          <br />
          <br />
          Debe realizar su pago en los próximos 5 minutos, de lo contrario, su cita será anulada automáticamente.
          <br />
          <br />
        </p>
        <div className='telemedicina-checkbox-container'>
          <input
            className='telemedicina-checkbox'
            type='checkbox'
            required
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <span style={{ textAlign: 'justify' }}>Al agendar su cita, usted declara constar con la facultad de acceder al servicio de atención médica y acepta que su atención será vía telemedicina y acepta que su atención será vía telemedicina y lo descrito en TÉRMINOS, CONDICIONES GENERALES Y CONSENTIMIENTO INFORMADO.</span>
        </div>
        <div className='buttons'>
          <button
            disabled={!isChecked || isLoading}
            onClick={() => setVoucherModal(true)}
            type='submit'>
            {isLoading ? 'Cargando...' : 'Siguiente'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Terms;