import { useState } from 'react';
import './ImportantInformation.css';
import { Link, useLocation } from 'react-router-dom';

const ImportantInformation = () => {
  const [isChecked, setIsChecked] = useState(false);
  const location = useLocation();
  const { dataLabels, clientData, dataHour } = location.state as any;
  console.log('dataLabels', dataLabels, 'clientData', clientData, 'dataHour', dataHour);

  const handleCheckboxChange = () => setIsChecked(!isChecked);

  return (
    <div className='appBody'>
      <div className='box-terms'>
        <div className='containerTitle' style={{ textAlign: 'center' }}>Información importante</div>
        <p style={{ padding: '0 20px' }}>
          Estimado paciente,
          <br />
          <br />
          La emisión de la licencia médica esta determinada bajo el criterio del meédico profesional y esta regulada por el Decreto con Fuerza de Ley N° 3 del Ministerio de Salud.
          <br />
          <br />
          El realizar el pago de la consulta no obliga al médico ni da derecho a la emisión de una licencia médica.
          <br />
          <br />
          Si acepta, marque la siguiente casilla:
        </p>
        <div className='telemedicina-checkbox-container'>
          <input
            className='telemedicina-checkbox'
            type='checkbox'
            required
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <span>Sí, acepto</span>
        </div>
        <div className='buttons'>
          <Link to='/hour-reservation' className='cancelButtonReserva' state={{ dataHour, clientData, dataLabels }}>
            <button type="submit" className='confirmButtonReserva' disabled={!isChecked} style={{marginLeft: '30%'}}>
              Siguiente
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ImportantInformation;