import { Slider } from '@mui/material';
import React from 'react';
import './QualifyAttention.css';

const QualifyAttention = () => {
  return (
    <div className='appBody'>
      <div className='box-qualify'>
        <div className='containerTitle'><p>Califica tu atención</p></div>
        <p className='qualify-subtitle'>Tu opinión es importante para nosotros, ayúdanos completando esta breve encuesta.</p>
        <div className='qualify-slider-container'>
          <p>¿Como calificas la experiencia en tu consulta de telemedicina?</p>
          <Slider
            aria-label="Small steps"
            defaultValue={5}
            // getAriaValueText={valuetext}s
            step={1}
            marks={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => ({
              value,
              label: value.toString(),
            }))}
            min={0}
            max={10}
            valueLabelDisplay="auto"
          />
        </div>
        <div className='qualify-slider-container'>
          <p>¿Como calificas la atención del personal médico?</p>
          <Slider
            aria-label="Always visible"
            defaultValue={5}
            step={1}
            marks={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => ({
              value,
              label: value.toString(),
            }))}
            min={0}
            max={10}
            valueLabelDisplay="auto"
          />
        </div>
        <div className='qualify-comment-container'>
          <p>Queremos seguir mejorando para ti. Déjanos tu opinión, comentarios o seguerencia</p>
          <textarea
            className='qualify-textarea'
            placeholder='Escribe aquí tu mensaje'
          />
        </div>
        <div className='buttons'>
          <button
            type='submit'>Enviar</button>
        </div>
      </div>
    </div>
  );
}

export default QualifyAttention;