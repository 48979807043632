import Popup from 'reactjs-popup';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import updateLocale from 'dayjs/plugin/updateLocale';
import { PopUpConfirmationProps } from './Interfaces';
import { CiClock2, CiHospital1, CiStethoscope } from "react-icons/ci";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { FaRegCheckCircle } from "react-icons/fa";
import { FiMapPin } from "react-icons/fi";
import 'dayjs/locale/es';
import '../styles/popUpConfirmation.css';
import calendarIcon from '../assets/images/Calendarui_Verde.png';
import clockIcon from '../assets/images/Reloj_Verde.png';
import estetoscopioIcon from '../assets/images/Estetoscopio_Blanco.png'
import sucursalIcon from '../assets/images/Sucursal_Blanco.png'
import ubicacionIcon from '../assets/images/Ubicacion_Blanco.png'
import profesionalIcon from '../assets/images/Profesional_Blanco.png'
import { useEffect } from 'react';

dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1,
  weekdays: [
    'Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado',
  ],
});


const PopUpConfirmation = ({
  isOpen,
  closeModal,
  hourSelected,
  dataLabels,
  dataClient,
}: PopUpConfirmationProps) => {

  console.log('hourSelected', hourSelected);
  const handleOpen = () => {
    // Cuando se abre el modal, desplazar el scroll del contenido al principio
    setTimeout(() => {
      const modalId = document.getElementsByClassName('popup-content');
      if (modalId && modalId[0]) {
        modalId[0].scrollTop = 0;
      }
    }, 0);
  };

return (
  <Popup
    modal
    lockScroll={false}
    closeOnDocumentClick={false}
    position="center center"
    open={isOpen}
    onClose={closeModal}
    onOpen={handleOpen}

  >
    <div className="modal">
      <div className="titleModalContent">
        <FaRegCheckCircle size={50} color='white' style={{ marginBottom: '1%', marginTop: '1%' }} />
        <p className="subTitleText">
          {' '}
          Confirmación de datos
        </p>
      </div>
      <div className="content">
        <div className="body">
          <div className="titleBodyPopUp">
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', height: '50%', flex: 5 }}>
              <div style={{ width: '16%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src={calendarIcon} alt='calendar' style={{ width: '50%', height: '50%' }} />
              </div>
              {/* <MdOutlineCalendarMonth style={{flex: 1}} size={30} color='#51b543'/> */}
              <div className='titleTextLeft'>
                {dayjs(hourSelected.reservationHour).format('dddd')}
                {' '}
                {dayjs(hourSelected.reservationHour).format('D')}
                {' '}
                de
                {' '}
                {dayjs(hourSelected.reservationHour).locale('es').format('MMMM')}
                {' '}
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', height: '50%', flex: 5 }}>
              <div style={{ width: '16%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src={clockIcon} alt='calendar' style={{ width: '45%', height: '45%' }} />
              </div>
              {/* <CiClock2 style={{ flex: 1 }} size={30} color='#51b543' /> */}
              <div className='titleTextRight'>
                {' '}
                {hourSelected?.reservationHour?.substring(11, 16)}
                {' '}
                hrs
              </div>
            </div>
          </div>
          <p className='subTitleText'>
            Datos de atención
          </p>
          <div className="rowInfoConfirmation">
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '11%', height: '70%', marginTop: 0, marginBottom: 0 }}>
              <img src={estetoscopioIcon} alt='calendar' style={{ width: '100%', height: '100%' }} />
            </div>
            {/* <CiStethoscope size={30} color='white' /> */}
            <div style={{ flexDirection: 'column', width: '100%' }}>
              <div className='dataLabelTitle'>
                Especialidad
              </div>
              <div style={{marginTop: 0, marginBottom: 0, fontWeight: 'bold', textAlign: 'justify'}}>
                {dataLabels.specialty}
              </div>
            </div>
          </div>
          <div style={{ borderTop: "1px solid white", margin: "0px 0" }} />
          <div className="rowInfoConfirmation">
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '11%', height: '70%', marginTop: 0, marginBottom: 0 }}>
              <img src={estetoscopioIcon} alt='calendar' style={{ width: '100%', height: '100%' }} />
            </div>
            {/* <CiStethoscope size={30} color='white' /> */}
            <div style={{ flexDirection: 'column', width: '100%' }}>
              <div className='dataLabelTitle'>
                Prestación
              </div>
              <div style={{marginTop: 0, marginBottom: 0, fontWeight: 'bold', textAlign: 'justify'}}>
                {dataLabels.category.toLowerCase().replace(/\b\w/g, l => l.toUpperCase())}
              </div>
            </div>
          </div>
          <div style={{ borderTop: "1px solid white", margin: "0px 0" }} />
          <div className="rowInfoConfirmation">
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '11%', height: '65%' }}>
              <img src={profesionalIcon} alt='calendar' style={{ width: '100%', height: '100%' }} />
            </div>
            {/* <FiMapPin size={30} color='white' /> */}
            <div style={{ flexDirection: 'column', width: '100%' }}>
              <div className='dataLabelTitle'>
                Profesional
              </div>
              <div style={{marginTop: 0, marginBottom: 0, fontWeight: 'bold', textAlign: 'justify'}}>
                {hourSelected.profesionalName}
              </div>
            </div>
          </div>
          <div className="scrollrowInfoConfirmation">
            <div>
              Preparación:
              {' '}
              {hourSelected.preparacion}
            </div>
          </div>
        </div>
        <div className="buttom">
          <div style={{ width: '40%' }}>
            <button type="button" className="confirmButtonCancel" onClick={() => closeModal()}>
              Cancelar
            </button>
          </div>
          <Link style={{ width: '40%' }} to="/s2terms" state={{ dataHour: hourSelected, clientData: dataClient, dataLabels }}>
            <button type="button" className="confirmButtonReserva">
              Siguiente
            </button>
          </Link>
        </div>
      </div>
    </div>
  </Popup>
)};

export default PopUpConfirmation;
